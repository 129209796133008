import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Pre Open WOD 7 `}</strong></p>
    <p>{`*`}<em parentName="p">{`This is the last Pre Open WOD.  If you need to make any Pre Open
WOD’s up you must do so by the end of this week.  `}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`RX Division`}</em></strong></p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`10-8-6 reps each:`}</p>
    <p>{`DB Snatch/Arm, alternating (60/40`}{`#`}{`)`}</p>
    <p>{`*`}{`*`}{`you cannot drop the dumbbell and must alternate hands between each
rep when the DB is on the floor.  Must touch the floor and reach full
extension at the top.`}{`*`}{`*`}</p>
    <p>{`Pistols/Leg, alternating (Must reach full extension before touching
opposite foot to floor.  Heal of the extended foot cannot touch the
floor but can be held if needed.)`}</p>
    <p>{`100ft Bear Crawl (after the set of 6 pistols)`}</p>
    <p>{`16-14-12 reps each:`}</p>
    <p>{`Thrusters (95/65)`}</p>
    <p>{`Burpees`}</p>
    <p>{`200ft Farmers Walk (70/53`}{`#`}{` KB’s per hand, after the set of 12 burpees)`}</p>
    <p>{`20-18-16 reps each:`}</p>
    <p>{`HSPU’s (entire hand on 25`}{`#`}{` plate, head to ab mat)`}</p>
    <p>{`K2E’s (knees must touch elbows, not triceps)`}</p>
    <p>{`300ft Handstand Walk.  Y`}<em parentName="p">{`ou must complete 5ft of HS Walk per attempt or
you must start over at the start of that 5ft increment.  For scoring
purposes each 5ft increment = 1 rep.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`Scaled/Masters Divisions`}</em></strong></p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`10-8-6 reps each:`}</p>
    <p>{`DB Snatch/Arm, alternating (45/30`}{`#`}{`)`}</p>
    <p>{`*`}{`you cannot drop the dumbbell and must alternate hands between each
rep. Must touch the floor and reach full extension at the top.`}{`*`}</p>
    <p>{`Weighted Reverse Lunges/Leg, alternating (holding 45/30`}{`#`}{` DB to chest)`}</p>
    <p>{`100ft Bear Crawl (after the set of 6 lunges)`}</p>
    <p>{`16-14-12 reps each:`}</p>
    <p>{`Thrusters (75/45)`}</p>
    <p>{`Burpees`}</p>
    <p>{`200ft Farmers Walk (45/35`}{`#`}{` KB’s per hand, after the set of 12 burpees)`}</p>
    <p>{`20-18-16 reps each:`}</p>
    <p>{`HSPU’s (knees on 24/20″ box, head to floor)`}</p>
    <p>{`Hanging Knee Raise (knees must end above parallel to floor, legs reach
full extension in the down position)`}</p>
    <p>{`300ft Crab Crawl.  Each 5ft increment of Crab Crawl = 1 rep.`}</p>
    <p><strong parentName="p">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports Nutrition
seminar with Dawn from Sports Nutrition 2 Go in Cincinnati. The seminar
will be from 12-2pm. \\$35/person for the seminar. We will need 20 people
in order to have this so please email Daniel\\@crossfitlouisvilleeast.com
if you’d like to attend.`}</strong></p>
    <p><strong parentName="p">{`Subjects covered will include:`}</strong></p>
    <p><strong parentName="p">{`Hydration—How impacts performance, electrolytes, what to drink`}</strong></p>
    <p><strong parentName="p">{`Breakfast—effects afternoon performance by 5% and effects appetite`}</strong></p>
    <p><strong parentName="p">{`Nutrient Timing—what to eat before/after, when`}</strong></p>
    <p><strong parentName="p">{`Sleep and Nutrition on performance---Discuss percentages on outcomes`}</strong></p>
    <p><strong parentName="p">{`Discuss different nutrition philosophies`}</strong></p>
    <p><strong parentName="p">{`Supplements and Labs: what to take and which labs to draw`}</strong></p>
    <p><strong parentName="p">{`Also: She will cover what ever the audience would like! If you would
like to attend and you want to forward questions ahead of time please
send them to: Daniel\\@crossfitlouisvilleeast.com.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Want to learn the exact number of calories your body burns each day?
Metabolic Testing will be offered before and after the seminar as well!
\\$65 for Metabolic Testing.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      